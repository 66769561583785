<template>
  <div>
    <v-row dense>
      <v-col md="2">
        <v-select
          :items="streetTypes"
          v-model="facility.facility.addressStreetType"
          label=""
          dense
          clearable
        ></v-select>
      </v-col>
      <v-col md="3">
        <v-text-field
          v-model="facility.facility.addressStreet"
          label="nazwa ulicy/placu/osiedla"
          dense
        ></v-text-field>
      </v-col>
      <v-col md="1">
        <v-text-field
          v-model="facility.facility.addressBuildingNumber"
          label="nr domu"
          dense
        ></v-text-field>
      </v-col>
      <v-col md="1">
        <v-text-field
          v-model="facility.facility.addressFlatNumber"
          label="/ m"
          dense
        ></v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field
          v-model="facility.facility.addressCityName"
          label="miejscowość"
          dense
        ></v-text-field>
      </v-col>
      <v-col md="1">
        <v-text-field
          v-model="facility.facility.addressCityNumber"
          label="nr domu"
          dense
        ></v-text-field>
      </v-col>
      <v-col md="1">
        <v-text-field
          v-model="facility.facility.addressExtraNumber"
          label="/ m"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col md="6">
        <v-text-field
          v-model="facility.facility.addressPostcode"
          label="kod"
          dense
          @change="$v.$touch()"
          @keydown="$v.$touch()"
          :error-messages="addressPostcodeErrors"
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          v-model="facility.facility.addressPostCityName"
          label="poczta"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col md="12">
        <v-text-field
          v-model="facility.facility.nCity"
          label="najbliższe większe miasta"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import { helpers } from "vuelidate/lib/validators";
const postcodePattern = helpers.regex("slug", /^\d{2}-\d{3}$/);

export default {
  name: "FacilityEditAddressPostal",
  props: ["facility"],
  components: {},
  data: () => ({
    streetTypes: ["al.", "os.", "pl.", "ul."],
  }),
  validations: {
    facility: {
      facility: {
        addressPostcode: {
          postcodePattern
        },
      },
    },
  },
  computed: {
    addressPostcodeErrors(){
      const errors = [];
      if (!this.$v.facility.facility.addressPostcode.$dirty) return errors;
      !this.$v.facility.facility.addressPostcode.postcodePattern && errors.push("kod jest nieprawidłowy");
      return errors;
    }
  },
  methods: {},
  mounted() {},
};
</script>


<style>
</style>