<template>
  <v-form>

    <v-row dense>
      <v-col>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <v-checkbox
                label="nie w mailingu"
                v-model="facility.facility.isMailingDisabled"
                :value=1
                dense
              ></v-checkbox>
            </div>
          </template>
          <span>nie wysyłaj mailingu do obiektu</span>
        </v-tooltip>

      </v-col>
      <v-col>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <v-checkbox
                label="nie sms faktura"
                v-model="facility.disable_invoice_sms_notifications"
                :value=1
                dense
              ></v-checkbox>
            </div>
          </template>
          <span>nie wysyłaj powiadomienia o wystawienia faktury sms-em</span>
        </v-tooltip>
      </v-col>

      <v-col>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <v-checkbox
                label="cały adres www"
                v-model="facility.facility.showWwwHost"
                :value=1
                dense
              ></v-checkbox>
            </div>
          </template>
          <span>pokaż pełen adres prywatnej strony internetowej w serwisach</span>
        </v-tooltip>
      </v-col>
      <v-col>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <v-checkbox
                label="nie info"
                v-model="facility.facility.showGoodPricesInfo"
                :value=1
                dense
              ></v-checkbox>
            </div>
          </template>
          <span>ukryj informacje 'powołaj się na informacje FIRMY METEOR ...'</span>
        </v-tooltip>
      </v-col>
      
      <!--<v-col>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <v-checkbox
                label="nie sms zapytanie"
                v-model="facility.facility.disableSmsNotification"
                :value=1
                dense
              ></v-checkbox>
            </div>
          </template>
          <span>nie wysyłaj powiadomienia o nowym zapytaniu</span>
        </v-tooltip>
      </v-col>-->
    </v-row>

    <v-card outlined>
      <v-card-subtitle class="pa-2 font-weight-bold">telefony</v-card-subtitle>
      <v-divider></v-divider>
      <FacilityEditPhones :facility="facility" @phone:update="$emit('autosave')"/>
    </v-card>

    <v-card
      outlined
      class="mt-5 "
    >
      <v-card-subtitle class="pa-2 font-weight-bold">adresy www</v-card-subtitle>
      <v-divider></v-divider>
      <FacilityEditAddresses
        :facility="facility"
        :availableTypes=[1,2,3,4,5,6]
        :validateTypeDuplication="false"
        @address:update="$emit('autosave')"
        addressLabel="adres url"
      />
    </v-card>

    <v-card
      outlined
      class="mt-5 "
    >
      <v-card-subtitle class="pa-2 font-weight-bold">adresy email</v-card-subtitle>
      <v-divider></v-divider>
      <FacilityEditAddresses
        :facility="facility"
        :availableTypes=[7,8,9,11,12,13]
        :validateTypeDuplication="true"
        addressLabel="adres email"
        @address:update="$emit('autosave')"
      />
    </v-card>

    <v-card
      outlined
      class="mt-5 "
    >
      <v-card-subtitle class="pa-2 font-weight-bold">komunikatory</v-card-subtitle>
      <v-divider></v-divider>
      <FacilityEditAddresses
        :facility="facility"
        :availableTypes=[10,14]
        :validateTypeDuplication="true"
        addressLabel="numer / adres"
        @address:update="$emit('autosave')"
      />
    </v-card>
  </v-form>
</template>

<script>
import FacilityEditAddresses from "./../FacilityEditAddresses";
import FacilityEditPhones from "../Phones/FacilityEditPhones";

export default {
  name: "FacilityEditTabAddress",
  props: ["facility"],
  components: {
    FacilityEditAddresses,
    FacilityEditPhones
  },
  data: () => ({}),
  computed: {},
  methods: {
  },
  mounted() {},
};
</script>


<style>
</style>